import React from 'react';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  return (
    <StyledPortfolio id="portfolio">
      <TopSkew />
      <TextContainer>
        <Header>Sprawdź wykonane przez nas prace</Header>
        <Text>
          <i>
            Poddaj nasze prace własnej ocenie i wejdź z nami we współpracę jeśli
            Ci się spodobały.
          </i>
        </Text>
      </TextContainer>
      <ButtonContainer>
        <Link
          to="/movies"
          style={{
            height: '200px',
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon className="portfolio-icon">local_movies</Icon>
          <Text>Filmy</Text>
        </Link>
        <Separator></Separator>
        <Link
          to="/gallery"
          style={{
            height: '200px',
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon className="portfolio-icon">photo_camera</Icon>
          <Text>Galeria</Text>
        </Link>
      </ButtonContainer>
    </StyledPortfolio>
  );
};

export default Portfolio;

const StyledPortfolio = styled.section`
  width: 100vw;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  padding-bottom: 150px;
  z-index: 1;
`;

const TopSkew = styled.div`
  width: 200vw;
  height: 100px;
  background-color: ${({ theme }) => theme.color.black};
  -moz-transform: translate(0px, -100%) skew(0deg, -12deg);
  -webkit-transform: translate(0px, -100%) skew(0deg, -12deg);
  -o-transform: translate(0px, -100%) skew(0deg, -12deg);
  -ms-transform: translate(0px, -100%) skew(0deg, -12deg);
  transform: translate(0px, -100%) skew(0deg, -12deg);
  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, NaN, 0);
  -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, NaN, 0);
  box-shadow: inset 0px 0px 0px 0px rgba(0, 0, NaN, 0);

  @media (min-width: 448px) {
    height: 150px;
  }
  @media (min-width: 700px) {
    transform: translate(0px, -100%) skew(0deg, -8deg);
  }
  @media (min-width: 1024px) {
    transform: translate(0px, -100%) skew(0deg, -5deg);
  }
  @media (min-width: 1700px) {
    transform: translate(0px, -100%) skew(0deg, -4deg);
  }
`;

const TextContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.div`
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.desktop.l};
  }
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.s};
  font-weight: ${({ theme }) => theme.font.weight.thin};

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.mobile.m};
  }
`;

const Separator = styled.div`
  height: 200px;
  width: 1px;
  background-color: #666;

  @media (min-width: 1024px) {
    height: 300px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20%;

  .portfolio-icon {
    font-size: 60px;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    margin-top: 10%;
    padding-bottom: 100px;

    .portfolio-icon {
      font-size: 100px;
      margin-bottom: 25px;
    }
  }
`;
