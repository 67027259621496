import React from 'react';
import styled from 'styled-components';

const Player = () => {
  return (
    <StyledPlayer id="player">
      {/* controls parameter bugs the player in firefox and video disappears when scrolling */}
      <video width="100%" controls muted loop autoPlay>
        <source src="http://www.superfilmowanie.pl/klip.mp4" type="video/mp4" />
      </video>
    </StyledPlayer>
  );
};

export default Player;

const StyledPlayer = styled.div`
  width: 100%;
  margin-top: 56px;
  z-index: 1;
`;
