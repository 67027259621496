import React from 'react';
import styled from 'styled-components';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';

const menuLinks = [
  { text: 'HOME', href: 'player' },
  { text: 'OFERTA', href: 'oferta' },
  { text: 'PORTFOLIO', href: 'portfolio' },
  { text: 'POLECAMY', href: '/polecamy' },
  { text: 'KONTAKT', href: 'kontakt' },
];

const Menu = props => {
  if (useLocation().pathname === '/')
    return (
      <StyledMenu isOpen={props.isOpen}>
        {menuLinks.map(item => (
          <InnerMenu key={'innerMenu__' + item.href}>
            {item.text === 'POLECAMY' ? (
              <RouterLink to={item.href}>
                <MobileMenuElement>
                  <span>{item.text}</span>
                </MobileMenuElement>
              </RouterLink>
            ) : (
              <Link
                to={item.href}
                offset={-100}
                smooth={true}
                duration={1000}
                key={'link__' + item.href}>
                <MobileMenuElement>
                  <span>{item.text}</span>
                </MobileMenuElement>
              </Link>
            )}
          </InnerMenu>
        ))}
      </StyledMenu>
    );
  else
    return (
      <StyledMenu isOpen={props.isOpen}>
        {menuLinks.map(item => (
          <InnerMenu key={'innerMenu__' + item.href}>
            {item.text === 'POLECAMY' ? (
              <RouterLink to={item.href}>
                <MobileMenuElement>
                  <span>{item.text}</span>
                </MobileMenuElement>
              </RouterLink>
            ) : (
              <RouterLink to="/" key={'link__' + item.href}>
                <MobileMenuElement>
                  <span>{item.text}</span>
                </MobileMenuElement>
              </RouterLink>
            )}
          </InnerMenu>
        ))}
      </StyledMenu>
    );
};

export default Menu;

const StyledMenu = styled.div`
  ${({ theme }) => theme.shadow};
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? '56px' : '0')};
  left: 0;
  width: 100vw;
  height: 300px;
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
  z-index: -1;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  color: ${({ isOpen }) => (isOpen ? '#fff' : '#141414')};
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  transition: ${({ isOpen }) =>
    isOpen
      ? 'transform 0.3s 0s, color 0.35s 0.2s ease-in-out'
      : 'transform 0.3s 0s, color 0s 0s ease-in-out'};

  -webkit-transition: ${({ isOpen }) =>
    isOpen
      ? 'transform 0.3s 0s, color 0.35s 0.2s ease-in-out'
      : 'transform 0.3s 0s, color 0s 0s ease-in-out'};

  -moz-transition: ${({ isOpen }) =>
    isOpen
      ? 'transform 0.3s 0s, color 0.35s 0.2s ease-in-out'
      : 'transform 0.3s 0s, color 0s 0s ease-in-out'};

  -ms-transition: ${({ isOpen }) =>
    isOpen
      ? 'transform 0.3s 0s, color 0.35s 0.2s ease-in-out'
      : 'transform 0.3s 0s, color 0s 0s ease-in-out'};

  -o-transition: ${({ isOpen }) =>
    isOpen
      ? 'transform 0.3s 0s, color 0.35s 0.2s ease-in-out'
      : 'transform 0.3s 0s, color 0s 0s ease-in-out'};
`;

const InnerMenu = styled.div`
  text-align: center;
  height: 20%;
  width: 100%;
  border-top: 1px solid #1b1b1b;
`;

const MobileMenuElement = styled.div`
  width: 100%;
  height: 100%;

  span {
    position: relative;
    top: 30%;
  }
`;
