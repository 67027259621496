import React from 'react';
import styled from 'styled-components';
import { SocialIcon } from 'react-social-icons';
import { Query } from '@apollo/react-components';
import { gql } from 'apollo-boost';

const FETCH_CONTACT_INFO = gql`
  query {
    contactInfoes {
      header
      text
      email
      phone1
      phone2
      fb
      ig
      yt
      vimeo
    }
  }
`;

const Contact = () => {
  return (
    <StyledContact id="kontakt">
      <TopSkew />
      <Query query={FETCH_CONTACT_INFO}>
        {({ loading, error, data }) => {
          if (loading) return <p>Ładowanie treści...</p>;
          if (error)
            return (
              <p>
                Wystąpił błąd, odśwież stronę lub spróbuj ponownie później.
              </p>
            );
          return (
            <div>
              <TextContainer>
                <Header>{data.contactInfoes[0].header}</Header>
                <Text>
                  <i>{data.contactInfoes[0].text}</i>
                </Text>
              </TextContainer>
              <InfoContainer>
                <InfoText>
                  <a href={'mailto:' + data.contactInfoes[0].email}>
                    Email: {data.contactInfoes[0].email}
                  </a>
                  <br />
                  <a
                    className="mobilesOnly"
                    href={'tel:' + data.contactInfoes[0].phone1}>
                    tel: {data.contactInfoes[0].phone1}
                  </a>
                  <a href="/#" className="desktopOnly">
                    tel: {data.contactInfoes[0].phone1}
                  </a>
                  <br />
                  <a
                    className="mobilesOnly"
                    href={'tel:' + data.contactInfoes[0].phone1}>
                    tel: {data.contactInfoes[0].phone2}
                  </a>
                  <a href="/#" className="desktopOnly">
                    tel: {data.contactInfoes[0].phone2}
                  </a>
                </InfoText>
                <SocialMediaContainer>
                  <SocialIcon
                    network="facebook"
                    url={data.contactInfoes[0].fb}
                  />
                  <SocialIcon
                    network="instagram"
                    url={data.contactInfoes[0].ig}
                  />
                  <SocialIcon
                    network="youtube"
                    url={data.contactInfoes[0].yt}
                  />
                  <SocialIcon
                    network="vimeo"
                    url={data.contactInfoes[0].vimeo}
                  />
                </SocialMediaContainer>
              </InfoContainer>
            </div>
          );
        }}
      </Query>
      <Footer>Copyright © 2023. Wykonanie Sala Wojciech</Footer>
    </StyledContact>
  );
};

export default Contact;

const StyledContact = styled.section`
  width: 100vw;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};

  .desktopOnly {
    @media (max-width: 480px) {
      display: none;
    }
  }

  .mobilesOnly {
    @media (min-width: 480px) {
      display: none;
    }
  }
`;

const TopSkew = styled.div`
  width: 200vw;
  height: 100px;
  background-color: ${({ theme }) => theme.color.white};
  -moz-transform: translate(0px, -100%) skew(0deg, -12deg);
  -webkit-transform: translate(0px, -100%) skew(0deg, -12deg);
  -o-transform: translate(0px, -100%) skew(0deg, -12deg);
  -ms-transform: translate(0px, -100%) skew(0deg, -12deg);
  transform: translate(0px, -100%) skew(0deg, -12deg);
  -webkit-box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 448px) {
    height: 150px;
  }
  @media (min-width: 700px) {
    transform: translate(0px, -100%) skew(0deg, -8deg);
  }
  @media (min-width: 1024px) {
    transform: translate(0px, -100%) skew(0deg, -5deg);
  }
  @media (min-width: 1700px) {
    transform: translate(0px, -100%) skew(0deg, -4deg);
  }
`;

const TextContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.div`
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.desktop.l};
  }
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.s};
  font-weight: ${({ theme }) => theme.font.weight.thin};

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.mobile.m};
    font-weight: ${({ theme }) => theme.font.weight.thin};
  }
`;

const InfoContainer = styled.div`
  margin-top: 100px;
`;

const InfoText = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;

  a {
    display: block;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }
`;

const SocialMediaContainer = styled.div`
  width: 70%;
  margin: 80px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    width: 30%;
    margin-top: 150px;
  }
`;

const Footer = styled.div`
  width: 100vw;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.mobile.s};
  font-weight: ${({ theme }) => theme.font.weight.thin};
  margin-bottom: 20px;
`;
