import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import MasterStyle from './assets/styles/MasterStyle';
import Main from './pages/Main';
import Polecamy from './pages/Polecamy';
import Movies from './pages/Movies';
import Gallery from './pages/Gallery';

const App = () => {
  return (
    <MasterStyle>
      <Router>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/polecamy" component={Polecamy} />
          <Route path="/movies" component={Movies} />
          <Route path="/gallery" component={Gallery} />
        </Switch>
      </Router>
    </MasterStyle>
  );
};

export default App;
