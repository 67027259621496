import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

html,
body {
  width: 100vw;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #141414;
  background-color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: none;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

html {
  font-size: 1rem;
}

 ::-webkit-scrollbar {
   width: 5px;
 }
 
 /* Track */
 ::-webkit-scrollbar-track {
   background: #141414;
 }
 
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #FED136;
 }
 
`;
export default GlobalStyle;
