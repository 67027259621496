import React from 'react';
import styled from 'styled-components';
import Topbar from '../components/Topbar';
import { Query } from '@apollo/react-components';
import { gql } from 'apollo-boost';

const FETCH_MOVIES = gql`
  query {
    moviesInfoes {
      links
    }
  }
`;

const Movies = () => {
  return (
    <StyledMovies>
      <Topbar />

      <Query query={FETCH_MOVIES}>
        {({ loading, error, data }) => {
          if (loading) return <p>Ładowanie treści...</p>;
          if (error)
            return (
              <p>
                Wystąpił błąd, odśwież stronę lub spróbuj ponownie później.
              </p>
            );
          return (
            <div>
              {data.moviesInfoes[0].links.map(movie => (
                <div class="frame-container">
                  <iframe
                    class="frame"
                    frameborder="0"
                    title={movie}
                    gesture="media"
                    scrolling="no"
                    allow="encrypted-media"
                    allowfullscreen
                    src={movie}></iframe>
                </div>
              ))}
            </div>
          );
        }}
      </Query>
    </StyledMovies>
  );
};

export default Movies;

const StyledMovies = styled.div`
  width: 100vw;
  margin-top: 56px;
  background-color: ${({ theme }) => theme.color.white};

  .frame-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-top: 12px;
  }
  .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
