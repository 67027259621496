import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import Menu from './Menu';
import Icon from '@material-ui/core/Icon';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link, animateScroll } from 'react-scroll';

const menuLinks = [
  { text: 'HOME', href: 'player' },
  { text: 'OFERTA', href: 'oferta' },
  { text: 'PORTFOLIO', href: 'portfolio' },
  { text: 'POLECAMY', href: '/polecamy' },
  { text: 'KONTAKT', href: 'kontakt' },
];

const Topbar = props => {
  const [isMenuOpen, setMenuState] = useState(false);

  const toggleMenu = () => {
    setMenuState(!isMenuOpen);
  };

  if (useLocation().pathname === '/')
    return (
      <StyledTopbar>
        <Logo src={logo} onclick={() => animateScroll.scrollToTop()} />
        <DesktopMenuContainer>
          {menuLinks.map(item => (
            <div key={'container__' + item.href}>
              {item.text === 'POLECAMY' ? (
                <RouterLink to={item.href}>
                  <DesktopMenuElement>
                    <span>{item.text}</span>
                  </DesktopMenuElement>
                </RouterLink>
              ) : (
                <Link
                  to={item.href}
                  offset={-100}
                  smooth={true}
                  duration={1000}
                  key={'link__' + item.href}>
                  <DesktopMenuElement>
                    <span>{item.text}</span>
                  </DesktopMenuElement>
                </Link>
              )}
            </div>
          ))}
        </DesktopMenuContainer>
        <Icon id="menu-btn" onClick={toggleMenu}>
          {isMenuOpen ? 'close' : 'menu'}
        </Icon>
        <Menu
          isOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          linkRef={props.refProp}
        />
      </StyledTopbar>
    );
  else
    return (
      <StyledTopbar>
        <Logo src={logo} onclick={() => animateScroll.scrollToTop()} />
        <DesktopMenuContainer>
          {menuLinks.map(item => (
            <div key={'container__' + item.href}>
              {item.text === 'POLECAMY' ? (
                <RouterLink to={item.href}>
                  <DesktopMenuElement>
                    <span>{item.text}</span>
                  </DesktopMenuElement>
                </RouterLink>
              ) : (
                <RouterLink to="/" key={'link__' + item.href}>
                  <DesktopMenuElement>
                    <span>{item.text}</span>
                  </DesktopMenuElement>
                </RouterLink>
              )}
            </div>
          ))}
        </DesktopMenuContainer>
        <Icon id="menu-btn" onClick={toggleMenu}>
          {isMenuOpen ? 'close' : 'menu'}
        </Icon>
        <Menu
          isOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          linkRef={props.refProp}
        />
      </StyledTopbar>
    );
};
export default Topbar;

const StyledTopbar = styled.div`
  position: fixed;
  float: left;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 56px;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.shadow};

  #menu-btn {
    padding-right: 16px;
    font-size: 32px;

    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media (min-width: 800px) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  height: 32px;
  padding-left: 16px;
  background-size: cover;

  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const DesktopMenuContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.mobile.m};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  display: flex;
  flex-direction: row;
  align-content: center;
  float: right;
  height: calc(100% - 3px);
`;

const DesktopMenuElement = styled.div`
  height: 100% !important;
  width: 100%;
  cursor: pointer;
  border-bottom: 3px solid ${({ theme }) => theme.color.black};

  span {
    top: 20px;
    position: relative;
    margin: 0 25px;
  }

  transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  -webkit-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  -moz-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  -ms-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  -o-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;

  :hover {
    color: ${({ theme }) => theme.color.main};
    border-bottom: 3px solid ${({ theme }) => theme.color.main};

    transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
    -webkit-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
    -moz-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
    -ms-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
    -o-transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  }
`;
