import React, { useRef } from 'react';
import Topbar from '../components/Topbar';
import Player from '../components/Player';
import Oferta from '../components/Oferta';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';
import Commercial from '../components/Commercial';

const Main = () => {
  const homeRef = useRef(null);
  return (
    <div>
      <Topbar refProp={homeRef} />
      <Player />
      <Oferta />
      <Portfolio />
      <Contact />
      <Commercial />
    </div>
  );
};

export default Main;
