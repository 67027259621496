import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './services/serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';

// 'https://api-euwest.graphcms.com/v1/ck76uh74601d801dl3dxwb1ws/master';
const GRAPHCMS_API = 'https://api-eu-central-1.graphcms.com/v2/ck76uh74601d801dl3dxwb1ws/master';

const client = new ApolloClient({
  uri: GRAPHCMS_API,
});

ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
