import React from 'react';
import styled from 'styled-components';
import Topbar from '../components/Topbar';
import { Query } from '@apollo/react-components';
import { gql } from 'apollo-boost';

const FETCH_RECOMMENDED_INFO = gql`
  query {
    recommendedInfoes {
      header
      text
      genres
      genre1Authors
      genre2Authors
      genre3Authors
    }
  }
`;

const Polecamy = () => {
  return (
    <StyledPolecamy>
      <Topbar />
      <PolecamyContainer>
        <Query query={FETCH_RECOMMENDED_INFO}>
          {({ loading, error, data }) => {
            if (loading) return <p>Ładowanie treści...</p>;
            if (error)
              return (
                <p>
                  Wystąpił błąd, odśwież stronę lub spróbuj ponownie później.
                </p>
              );

            return (
              <div>
                <TextContainer>
                  <Header>{data.recommendedInfoes[0].header}</Header>
                  <Text>
                    <i>{data.recommendedInfoes[0].text}</i>
                  </Text>
                </TextContainer>
                <DataContainer>
                  {/* Music */}
                  <Header>{data.recommendedInfoes[0].genres[0]}</Header>
                  {data.recommendedInfoes[0].genre1Authors.map(item => (
                    <PersonLink
                      href={item.split('+').pop()}
                      key={'link-music__' + item}>
                      {item.split('+')[0]}
                    </PersonLink>
                  ))}

                  {/* Photo */}
                  <Header>{data.recommendedInfoes[0].genres[1]}</Header>
                  {data.recommendedInfoes[0].genre2Authors.map(item => (
                    <PersonLink
                      href={item.split('+').pop()}
                      key={'link-photo__' + item}>
                      {item.split('+')[0]}
                    </PersonLink>
                  ))}

                  {/* Film */}
                  <Header>{data.recommendedInfoes[0].genres[2]}</Header>
                  {data.recommendedInfoes[0].genre3Authors.map(item => (
                    <PersonLink
                      href={item.split('+').pop()}
                      key={'link-film__' + item}>
                      {item.split('+')[0]}
                    </PersonLink>
                  ))}
                </DataContainer>
              </div>
            );
          }}
        </Query>
      </PolecamyContainer>
    </StyledPolecamy>
  );
};

export default Polecamy;

const StyledPolecamy = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
`;

const PolecamyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const TextContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.div`
  margin-top: 50px;
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.s};
  font-weight: ${({ theme }) => theme.font.weight.thin};
  width: 80%;
  margin: 0 auto;
`;

const DataContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const PersonLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-decoration: none;
  margin-bottom: 15px;
`;
