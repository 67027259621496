import React from 'react';
import styled from 'styled-components';

const Commercial = () => {
  return (
    <Container>
      <a
        href="http://www.weselezklasa.pl/kamerzysta-na-wesele,38/radomsko/"
        align="center"
        target="blank"
        title="Kamerzysta na wesele
Radomsko">
        <center>
          <img
            src="http://www.weselezklasa.pl/banery/Weselezklasa/banner728x90.gif"
            alt="Kamerzysta na wesele Radomsko"
          />
        </center>
      </a>
    </Container>
  );
};

export default Commercial;

const Container = styled.div`
  width: 100vw;
`;
