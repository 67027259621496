import React, { Component } from 'react';
import styled from 'styled-components';
import GridGallery from 'react-grid-gallery';
import Topbar from '../components/Topbar';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    fetch('/images.php')
      .then(response => response.json())
      .then(data => this.setState({ images: data }))
      .catch(error => console.log(error));
  }

  render() {
    return (
      <StyledGallery>
        <Topbar />
        <Container>
          <GridGallery
            images={this.state.images.map(img => ({
              src: 'http://www.superfilmowanie.pl/images/' + img,
              thumbnail: 'http://www.superfilmowanie.pl/images/' + img,
            }))}
            margin={8}
          />
        </Container>
      </StyledGallery>
    );
  }
}

export default Gallery;

const StyledGallery = styled.div`
  width: 100vw;
`;

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 56px;
  overflow: auto;
`;
