import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Query } from '@apollo/react-components';
import { gql } from 'apollo-boost';

const FETCH_OFERTA_INFO = gql`
  query {
    ofertaInfoes {
      text
    }
  }
`;

const Oferta = () => {
  return (
    <StyledOferta id="oferta">
      <Query query={FETCH_OFERTA_INFO}>
        {({ loading, error, data }) => {
          if (loading) return <p>Ładowanie treści...</p>;
          if (error)
            return (
              <p>
                Wystąpił błąd, odśwież stronę lub spróbuj ponownie później.
              </p>
            );

          return (
            <div>
              {data.ofertaInfoes[0].text.map(item => (
                <Fade right left={item.anim} key={'fade__' + item.header}>
                  <TextContainer>
                    <Header>{item.header}</Header>
                    <br />
                    <Text>
                      <i>{item.text}</i>
                    </Text>
                  </TextContainer>
                </Fade>
              ))}
            </div>
          );
        }}
      </Query>
    </StyledOferta>
  );
};

export default Oferta;

const StyledOferta = styled.section`
  width: 100vw;
  margin-bottom: 150px;
`;
const TextContainer = styled.div`
  color: ${({ theme }) => theme.color.black};
  width: 100vw;
  margin: 70px auto;
  text-align: center;

  @media (min-width: 1024px) {
    margin: 100px auto;
  }
`;
const Header = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.l};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.desktop.l};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
`;
const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.mobile.s};
  font-weight: ${({ theme }) => theme.font.weight.thin};
  width: 70%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.font.size.desktop.s};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    padding-bottom: 50px;
`;
